import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import ContactForm from "./contactForm";

const SeoMapSection = ({ mapUrl, heading, webDesignEastbourne }) => {
	const newHeading = heading || "Discuss your project with us";
	return (
		<section id="results" className="py-5 py-lg-7 bg-white h-100 ">
			<Container className="">
				<Row className="g-5  h-100">
					<Col style={{ minHeight: "100%" }} xl={6}>
						<iframe
							className="position-relative "
							src={mapUrl}
							width="100%"
							height="100%"
							style="border:0; min-height: 400px;"
							allowfullscreen=""
							loading="lazy"
							referrerpolicy="no-referrer-when-downgrade"
						></iframe>
					</Col>
					<Col xl={6} className="pe-xl-6">
						<h4 className="text-primary display-4 mb-4">{newHeading}</h4>
						<ContactForm />
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default SeoMapSection;
