import { useForm, ValidationError } from "@formspree/react";
import freeEmailDomains from "free-email-domains";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function HeroContactFormNew({ yellow, page }) {
	const [state, handleSubmit] = useForm("xknlnarw");
	const [stage, setStage] = useState(1);
	const [formData, setFormData] = useState({
		"full-name": "",
		_replyto: "",
		phone: "",
		url: "",
		"company-name": "",
	});
	const [emailError, setEmailError] = useState(null);

	const validateEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(email)) {
			return "Please provide a valid email address.";
		}
		const domain = email.split("@")[1];
		if (freeEmailDomains.includes(domain)) {
			return "Please provide a business email.";
		}
		return null;
	};

	const handleEmailChange = (e) => {
		const email = e.target.value;
		const error = validateEmail(email);
		setEmailError(error);
		setFormData((prevFormData) => ({
			...prevFormData,
			[e.target.name]: email,
		}));
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const emailInput = formData["_replyto"];
		const emailValidationError = validateEmail(emailInput);
		if (emailValidationError) {
			setEmailError(emailValidationError);
			return;
		}
		setEmailError(null);
		await handleSubmit(e);
	};

	const handleClick = (e) => {
		e.preventDefault();
		if (stage === 1) {
			setStage(2);
		} else {
			handleSubmit(e);
		}
	};

	if (state.succeeded) {
		window.location = "/thank-you";
		return null;
	}

	return (
		<>
			<Form className="w-100" noValidate onSubmit={handleFormSubmit}>
				<>
					<Form.Group
						className={`mb-4 ${stage === 1 ? "d-block" : "d-none"}`}
						controlId="full-name"
					>
						<Form.Control
							required
							placeholder="Name*"
							className="py-3 hero-input"
							name="full-name"
							type="text"
							pattern="\S+.*"
							onChange={handleChange}
						/>
					</Form.Group>
					<ValidationError
						prefix="Name"
						field="full-name"
						errors={state.errors}
					/>

					<Form.Group className="mb-4 d-none" controlId="page">
						<Form.Control
							value={page}
							placeholder="Page"
							className="py-3 hero-input"
							name="page"
							type="text"
							onChange={handleChange}
						/>
					</Form.Group>
					<ValidationError prefix="Page" field="page" errors={state.errors} />

					<Form.Group
						className={`mb-4 ${stage === 1 ? "d-block" : "d-none"}`}
						controlId="company-name"
					>
						<Form.Control
							placeholder="Company Name"
							className="py-3 hero-input"
							name="company-name"
							type="text"
							onChange={handleChange}
						/>
					</Form.Group>
					<ValidationError
						prefix="Company Name"
						field="company-name"
						errors={state.errors}
					/>
				</>

				<>
					<Form.Group
						className={`mb-4 ${stage === 2 ? "d-block" : "d-none"}`}
						controlId="phone"
					>
						<Form.Control
							required
							placeholder="Phone Number*"
							className="py-3 hero-input"
							name="phone"
							type="tel"
							pattern="\S+.*"
							onChange={handleChange}
						/>
					</Form.Group>
					<ValidationError prefix="Phone" field="phone" errors={state.errors} />

					<Form.Group
						className={`mb-4 ${stage === 2 ? "d-block" : "d-none"}`}
						controlId="email"
					>
						<Form.Control
							required
							placeholder="Email Address*"
							className="py-3 hero-input"
							name="_replyto"
							type="email"
							onChange={handleEmailChange}
						/>
						{emailError && (
							<div>
								<p
									style={{ fontSize: "90%" }}
									className="text-danger text-start"
								>
									{emailError}
								</p>
							</div>
						)}
					</Form.Group>
					{/* Remove default ValidationError for email as we handle it */}
					{/* <ValidationError prefix="Email" field="email" errors={state.errors} /> */}

					<Form.Group
						className={`mb-4 ${stage === 2 ? "d-block" : "d-none"}`}
						controlId="url"
					>
						<Form.Control
							placeholder="Website URL"
							className="py-3 hero-input"
							name="url"
							type="url"
							onChange={handleChange}
						/>
					</Form.Group>
					<ValidationError prefix="URL" field="url" errors={state.errors} />
					{/* <Form.Group
						className={`mb-4 ${stage === 3 ? "d-block" : "d-none"}`}
						controlId="annualTurnover"
					>
						<Form.Control
							placeholder="Annual Turnover"
							className="py-3 hero-input"
							name="annualTurnover"
							type="text"
							onChange={handleChange}
						/>
					</Form.Group>
					<ValidationError
						prefix="annualTurnover"
						field="annualTurnover"
						errors={state.errors}
					/> */}
					{/* <Form.Group
						className={`mb-4 ${stage === 3 ? "d-block" : "d-none"}`}
						controlId="noOfEmployees"
					>
						<Form.Control
							placeholder="No of Employees"
							className="py-3 hero-input"
							name="noOfEmployees"
							type="text"
							onChange={handleChange}
						/>
					</Form.Group>
					<ValidationError
						prefix="noOfEmployees"
						field="noOfEmployees"
						errors={state.errors}
					/> */}
					{/* <Form.Group
						className={`mb-4 ${stage === 3 ? "d-block" : "d-none"}`}
						controlId="definedMarketingBudget"
					>
						<Form.Check
							type="checkbox"
							name="definedMarketingBudget"
							label="Defined Marketing Budget"
							onChange={handleChange}
						/>
					</Form.Group>
					<ValidationError
						prefix="definedMarketingBudget"
						field="definedMarketingBudget"
						errors={state.errors}
					/> */}
					{/* <Form.Group
						className={`mb-4 ${stage === 3 ? "d-block" : "d-none"}`}
						controlId="averageOrderValue"
					>
						<Form.Control
							placeholder="Average Order Value"
							className="py-3 hero-input"
							name="averageOrderValue"
							type="text"
							onChange={handleChange}
						/>
					</Form.Group>
					<ValidationError
						prefix="averageOrderValue"
						field="averageOrderValue"
						errors={state.errors}
					/> */}

					{/* <Button
						className={`btn western white-link-yellow w-100 px-5 mt-4 py-2 ${
							stage < 3 ? "d-block" : "d-none"
						}`}
						variant="light-blue"
						style={{ borderRadius: "0.35rem" }}
						onClick={(e) => setStage(stage + 1)}
					>
						Next
					</Button> */}
					<Button
						variant="light-blue"
						style={{ borderRadius: "0.35rem" }}
						className={`btn western white-link-yellow w-100 px-5 py-2 `}
						type="submit"
						id="contact-send-btn"
						disabled={state.submitting}
					>
						Submit
					</Button>

					{/* <div className={` text-center ${stage === 3 ? "d-block" : "d-none"}`}>
						<Button
							variant="light-blue"
							style={{ borderRadius: "0.35rem" }}
							className={`btn western white-link-yellow w-100 px-5 py-2 `}
							type="submit"
							id="contact-send-btn"
							disabled={state.submitting}
						>
							Submit
						</Button>
					</div> */}
				</>
			</Form>
			<div className="d-flex justify-content-center">
				<div className="d-flex mt-4">
					<div
						className={`circle-large ${
							stage === 1 ? "active" : ""
						} d-flex justify-content-center align-items-center`}
					>
						<div
							className={`circle ${stage === 1 ? "active" : ""}`}
							onClick={(e) => setStage(1)}
						></div>
					</div>
					<div
						className={`circle-large ${
							stage === 2 ? "active" : ""
						} d-flex justify-content-center align-items-center`}
					>
						<div
							className={`circle ${stage === 2 ? "active" : ""}`}
							onClick={(e) => setStage(2)}
						></div>
					</div>
					<div
						className={`circle-large ${
							stage === 3 ? "active" : ""
						} d-flex justify-content-center align-items-center`}
					>
						<div
							className={`circle ${stage === 3 ? "active" : ""}`}
							onClick={(e) => setStage(3)}
						></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default HeroContactFormNew;
